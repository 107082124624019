import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Пейнтбол Dvrz
			</title>
			<meta name={"description"} content={"Найкращі враження від пейнтболу"} />
			<meta property={"og:title"} content={"Пейнтбол Dvrz"} />
			<meta property={"og:description"} content={"Найкращі враження від пейнтболу"} />
			<meta property={"og:image"} content={"https://nexialux.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexialux.com/img/24334548.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexialux.com/img/24334548.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexialux.com/img/24334548.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexialux.com/img/24334548.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="--color-lightD1" sm-padding="50px 0 50px 0" quarkly-title="Content-13">
			<Override slot="SectionContent" width="900px" lg-width="95%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				background="url(https://nexialux.com/img/2.jpg) 50% 50%/cover"
				border-radius="25px"
				margin="0px 0px 25px 0px"
				sm-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="40px 40px 160px 40px"
					flex-direction="column"
					width="50%"
					sm-padding="20px 20px 20px 20px"
					sm-width="100%"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Зв'яжіться з нами
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box md-margin="0px 0px 35px 0px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 18px/1.5 --fontFamily-sans"
						padding="0px 0px 0px 25px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="--color-primary"
					>
						Ми будемо раді почути вас і допомогти вам у плануванні вашої наступної пейнтбольної пригоди. Не соромтеся звертатися до нас за наступними контактними даними:
					</Text>
				</Box>
				<Box padding="0px 0px 0px 80px" md-padding="0px 0px 0px 25px" display="flex" flex-direction="column">
					<Link
						href="tel:067 915 95 86"
						color="#000000"
						font="normal 500 20px/1.5 --fontFamily-sans"
						text-decoration-line="initial"
						margin="0px 0px 8px 0px"
						display="block"
					>
						067 915 95 86
					</Link>
					<Link
						href="mailto:contact@nexialux.com"
						color="#000000"
						font="normal 500 20px/1.5 --fontFamily-sans"
						text-decoration-line="initial"
						margin="0px 0px 8px 0px"
						display="block"
					>
						contact@nexialux.com
					</Link>
					<Text
						color="#000000"
						font="normal 500 20px/1.5 --fontFamily-sans"
						text-decoration-line="initial"
						margin="0px 0px 8px 0px"
						display="block"
					>
						вул. Алматинська 70, м. Київ, 02000
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});